import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProgressBar } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";


class MainForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        guess: ""
    };
    this.handleCheckClick = this.handleCheckClick.bind(this);
    this.handleSkipClick = this.handleSkipClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPressTextInput = this.handleKeyPressTextInput.bind(this);
  }

  handleChange(event) {
    this.setState({guess: event.target.value});
  }

  handleKeyPressTextInput(e) {
    if (e.key === "Enter" && this.state.guess != "") {
      this.handleCheckClick(e);
    }
  }

  handleSkipClick(e) {
    console.log(this.state.guess);
    console.log('The skip button was clicked.');
    this.props.onSkipClick();
    this.setState({
      guess: ""
    });
  }

  handleCheckClick(e) {
    console.log(this.state.guess);
    console.log('The check button was clicked.');
    this.props.onCheckClick(this.state.guess);
    this.setState({
      guess: ""
    });
  }

  render() {
    return (
      <form onSubmit={e => { e.preventDefault(); }}>
      <div className="form-group">
        <input style={{fontSize: '2em'}} className="form-control" type="text" placeholder="type your answer here" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" value={this.state.guess} onChange={this.handleChange} onKeyPress={this.handleKeyPressTextInput}/>
      </div>
      <br />
      <div className="form-group" style={{textAlign: 'center'}}>
        <input onClick={this.handleSkipClick} style={{marginRight: '1em', width: '6em'}} className="btn btn-secondary" type="button" value="skip"/>
        <input onClick={this.handleCheckClick} style={{marginLeft: '1em', width: '6em'}} className="btn btn-primary" type="button" value="check"/>
      </div>
      </form>
    );
  }
}

class Exercise extends React.Component {
  constructor(props) {
    super(props);
    this.closeModalButton = React.createRef();

    const allVerbs = [
      ["to have", "had", "had"],
      ["to do", "did", "done"],
      ["to say", "said", "said"],
      ["to go", "went", "gone"],
      ["to get", "got", "got"], // pp gotten
      ["to make", "made", "made"],
      ["to know", "knew", "known"],
      ["to think", "thought", "thought"],
      ["to take", "took", "taken"],
      ["to see", "saw", "seen"],
      ["to come", "came", "come"],
      ["to find", "found", "found"],
      ["to give", "gave", "given"],
      ["to tell", "told", "told"],
      ["to feel", "felt", "felt"],
      ["to become", "became", "become"],
      ["to leave", "left", "left"],
      ["to put", "put", "put"],
      ["to mean", "meant", "meant"],
      ["to keep", "kept", "kept"],
      ["to let", "let", "let"],
      ["to begin", "began", "begun"],
      ["to show", "showed", "shown"],
      ["to hear", "heard", "heard"],
      ["to run", "ran", "run"],
      ["to hold", "held", "held"],
      ["to bring", "brought", "brought"],
      ["to write", "wrote", "written"],
      ["to sit", "sat", "sat"],
      ["to stand", "stood", "stood"],
      ["to lose", "lost", "lost"],
      ["to pay", "paid", "paid"],
      ["to meet", "met", "met"],
      ["to set", "set", "set"],
      ["to learn", "learnt", "learnt"], // sp and pp can be regular i.e. learned
      ["to lead", "led", "led"],
      ["to understand", "understood", "understood"],
      ["to speak", "spoke", "spoken"],
      ["to read", "read", "read"],
      ["to spend", "spent", "spent"],
      ["to grow", "grew", "grown"],
      ["to win", "won", "won"],
      ["to buy", "bought", "bought"],
      ["to send", "sent", "sent"],
      ["to build", "built", "built"],
      ["to fall", "fell", "fallen"],
      ["to cut", "cut", "cut"],
      ["to sell", "sold", "sold"],
      ["to drive", "drove", "driven"],
      ["to break", "broke", "broken"],
      ["to wear", "wore", "worn"],
      ["to hit", "hit", "hit"],
      ["to eat", "ate", "eaten"],
      ["to teach", "taught", "taught"],
      ["to catch", "caught", "caught"],
      ["to draw", "drew", "drawn"],
      ["to choose", "chose", "chosen"],
      ["to seek", "sought", "sought"],
      ["to deal", "dealt", "dealt"],
      ["to fight", "fought", "fought"],
      ["to throw", "threw", "thrown"],
      ["to rise", "rose", "risen"],
      ["to shoot", "shot", "shot"],
      ["to lie (to rest in a horizontal position on a surface)", "lay", "lain"],
      ["to lie (to give false information)", "lied", "lied"],
      ["to prove", "proved", "proved"], // pp can be proven
      ["to hang", "hung", "hung"], // can be hanged/hanged
      ["to forget", "forgot", "forgotten"],
      ["to shake", "shook", "shaken"],
      ["to fly", "flew", "flown"],
      ["to sing", "sang", "sung"],
      ["to beat", "beat", "beaten"],
      ["to cost", "cost", "cost"],
      ["to hurt", "hurt", "hurt"],
      ["to strike", "struck", "struck"],
      ["to sleep", "slept", "slept"],
      ["to stick", "stuck", "stuck"],
      ["to drink", "drank", "drunk"],
      ["to hide", "hid", "hidden"],
      ["to ride", "rode", "ridden"],
      ["to feed", "fed", "fed"],
      ["to spread", "spread", "spread"],
      ["to blow", "blew", "blown"],
      ["to burn", "burnt ", "burnt"], // can be burned / burned
      ["to lean", "leant", "leant"], // can be leaned / leaned
      ["to shut", "shut", "shut"],
      ["to bear", "bore", "born  / borne"],
      ["to wake", "woke ", "woken"], // can be waked / waked
      ["to steal", "stole", "stolen"],
      ["to cast", "cast", "cast"],
      ["to wrap", "wrapt", "wrapt"], // wrapped/wrapped
      ["to slide", "slid", "slid"], // pp can be slidden
      ["to bend", "bent", "bent"],
      ["to arrange", "arranged", "arranged"],
      ["to tear", "tore", "torn"],
      ["to ring", "rang", "rung"],
      ["to smell", "smelt", "smelt"], // can be smelled/smelled
      ["to light", "lit", "lit"],
      ["to swing", "swung", "swung"],
      ["to dig", "dug", "dug"]
    ];

    let numberOfVerbs = parseInt(this.props.numberOfVerbs);
    let verbs = allVerbs.slice(0, numberOfVerbs);

    for(let i = verbs.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i)
      const temp = verbs[i]
      verbs[i] = verbs[j]
      verbs[j] = temp
    }

    this.state = {
      history: [],
      modalShow: false,
      progress: 0,
      currentWord: 0,
      questionType: 0, // 0 - past simple, 1 - participle
      verbs: verbs
    }
    this.handleCheckClick = this.handleCheckClick.bind(this);
    this.handleSkipClick = this.handleSkipClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.getInfinitive = this.getInfinitive.bind(this);
    this.getAskedForm = this.getAskedForm.bind(this);
    this.modalOnShowCallback = this.modalOnShowCallback.bind(this);

  }

  handleSkipClick(guess) {
    let answer = this.state.verbs[this.state.currentWord][this.state.questionType + 1];
    let verb = this.getInfinitive(this.state.currentWord, this.state.verbs);
    this.state.history.push([verb, this.state.questionType, answer, "<skipped>"]);

    this.setState({
      modalShow: true,
      modalHeader: "Skipped!",
      modalText: 'By the way, do you know that the correct answer is "' + answer + '"?'
    });

    this.proceed();
  }

  handleCheckClick(guess) {
    let answer = this.state.verbs[this.state.currentWord][this.state.questionType + 1];
    console.log("|" + guess + "|");
    console.log("|" + answer + "|");

    let verb = this.getInfinitive(this.state.currentWord, this.state.verbs);
    this.state.history.push([verb, this.state.questionType, answer, guess]);
    if (guess == answer) {

    } else {
      this.setState({
        modalShow: true,
        modalHeader: "Wrong!",
        modalText: 'The correct answer is: "' + answer + '"'
      });
    }

    this.proceed();
  }

  proceed() {
    if (this.state.currentWord === this.state.verbs.length - 1) {
      console.log("done");
      console.log(this.state.history)
      this.setState({
        progress: 100
      });
      this.props.history.push({
        pathname: "/results.html",
        state: {
          history: this.state.history,
          numberOfVerbs: this.props.numberOfVerbs
        }
      });
    } else {
      this.setState((state, props) => ({
        currentWord: state.currentWord + 1,
        questionType: Math.round(Math.random()),
        progress: Math.round(((this.state.currentWord + 1) / this.state.verbs.length) * 100)
      }));
      console.log("from parent");
    }
  }

  modalOnShowCallback() {
    this.closeModalButton.current.focus();
  }

  getAskedForm(questionType) {
    if (questionType === 0) {
      return "simple past";
    } else {
      return "past participle";
    }
  }

  getInfinitive(currentWord, verbs) {
    return verbs[currentWord][0];
  }

  handleShow() {
    this.setState({
      modalShow: true
    });
  }

  handleClose() {
    this.setState({
      modalShow: false
    });
  }

  render () {
    return [
      <div clasName="row">
      <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/">Exercises</Link></li>
                <li class="breadcrumb-item active" aria-current="page">{this.props.numberOfVerbs} Most Common Irregular Verbs</li>
              </ol>
            </nav>
            </div>
      </div>,
      <div clasName="row">

      <div className="col-md-12">

      <Modal show={this.state.modalShow} onHide={this.handleClose} onShow={this.modalOnShowCallback}>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.state.modalText}</Modal.Body>
        <Modal.Footer>
          <Button ref={this.closeModalButton} variant="primary" onClick={this.handleClose}>OK</Button>
        </Modal.Footer>
      </Modal>

        <ProgressBar variant="success" striped="true" now={this.state.progress}/>
        <br/>

        <h1 id="main-question">What is the <span id="main-question-form">{this.getAskedForm(this.state.questionType)}</span> of <span id="main-question-verb">{this.getInfinitive(this.state.currentWord, this.state.verbs)}</span>?</h1>
        <br/>
        <MainForm onSkipClick={this.handleSkipClick} onCheckClick={this.handleCheckClick} questionType={this.state.questionType} verb={this.state.verbs[this.state.currentWord]}/>
      </div>
      </div>
    ]
  }
}


class ExerciseResults extends React.Component {
  constructor(props) {
    super(props);
    let history = props.location.state.history;
    let totalAnswers = history.length;
    let correctAnswers = history.filter(entry => entry[2] == entry[3]).length;
    let score = Math.ceil((correctAnswers / totalAnswers) * 100);
    this.state = {
      totalAnswers: totalAnswers,
      correctAnswers: correctAnswers,
      score: score,
      history: history,
      numberOfVerbs: props.location.state.numberOfVerbs,
      toLink: "/" + props.location.state.numberOfVerbs + "-most-common-irregular-verbs.html"
    }
    console.log(props);
  }
  render() {
    let historyWithIncorrectAnswers = this.state.history.filter(entry => entry[2] != entry[3]);
    let incorrectAnswers = historyWithIncorrectAnswers.map((entry, index) =>
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{entry[0]}</td>
        <td>{entry[1] == 0 ? "Simple Past" : "Past Participle"}</td>
        <td>{entry[2]}</td>
        <td>{entry[3]}</td>
      </tr>
    );

    let incorrectAnswersBlock;

    if (historyWithIncorrectAnswers.length != 0) {
      incorrectAnswersBlock = <div>
        <h4>Incorrect answers:</h4>
        <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Verb</th>
            <th scope="col">Asked Form</th>
            <th scope="col">Correct Answer</th>
            <th scope="col">Your Answer</th>
          </tr>
        </thead>
        <tbody>{incorrectAnswers}</tbody>
        </table>
      </div>
    } else {
      incorrectAnswersBlock = "";
    }
    return [
      <div clasName="row">
      <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/">Exercises</Link></li>
                <li class="breadcrumb-item active" aria-current="page">{this.state.numberOfVerbs} Most Common Irregular Verbs</li>
              </ol>
            </nav>
            </div>
      </div>,
      <div clasName="row">
      <div className="col-md-12">

<h1>Results</h1>
<p>You have answered {this.state.correctAnswers} questions correctly out of {this.state.totalAnswers}.</p>
<p>Your score is {this.state.score}%</p>

{incorrectAnswersBlock}

<Link to={this.state.toLink} className="btn btn-lg btn-block btn-outline-primary">Exercise again!</Link>

</div></div>
  ];
}
}

function Home() {
  return (
    <div className="col-md-12">
    <h1>Grammarium - irregular verb trainer!</h1>

    <p>Learn and exercise the most common English irregular verbs! You don't need to register or fill out any forms, just click on the "exercise" button below and start making progress!</p>

    <div class="card-deck mb-3 text-center">
      <div class="card mb-4 shadow-sm">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Newbie</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">20 <small class="text-muted">verbs</small></h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>20 most common English irregular verbs</li>
          </ul>
          <Link to="/20-most-common-irregular-verbs.html" className="btn btn-lg btn-block btn-outline-primary">Exercise!</Link>
        </div>
      </div>
      <div class="card mb-4 shadow-sm">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Seasoned</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">50 <small class="text-muted">verbs</small></h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>50 most common English irregular verbs</li>
          </ul>
          <Link to="/50-most-common-irregular-verbs.html" className="btn btn-lg btn-block btn-outline-primary">Exercise!</Link>
        </div>
      </div>
      <div class="card mb-4 shadow-sm">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Master</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">100 <small class="text-muted">verbs</small></h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>100 most common English irregular verbs</li>
          </ul>
          <Link to="/100-most-common-irregular-verbs.html" className="btn btn-lg btn-block btn-outline-primary">Exercise!</Link>
        </div>
      </div>
    </div>

    </div>
  )
}

let ExerciseWithRouter = withRouter(Exercise);
let ExerciseResultsWithRouter = withRouter(ExerciseResults);
let HomeWithRouter = withRouter(Home);

function App() {
  return (
<Router>
  <div className="container" style={{marginTop: '2em'}}>
    <Switch>
      <Route path="/results.html"><ExerciseResultsWithRouter/></Route>
      <Route path="/20-most-common-irregular-verbs.html"><ExerciseWithRouter numberOfVerbs="20"/></Route>
      <Route path="/50-most-common-irregular-verbs.html"><ExerciseWithRouter numberOfVerbs="50"/></Route>
      <Route path="/100-most-common-irregular-verbs.html"><ExerciseWithRouter numberOfVerbs="100"/></Route>
      <Route path="/"><HomeWithRouter/></Route>
    </Switch>
  </div>
</Router>
  );
}

export default App;
